import Alpine from "alpinejs";
import $ from "jquery";

import "../profile/js/account-button";
import "./js/background-color";
import "./js/banners";
import {minimizeBlocks} from "./js/blocks";
import {checkConsentContent} from "./js/consent";
import "./js/csrf";
import "./js/index";
import "./js/link-masking";
import {loadWidgets} from "./js/load-widgets";
import "./js/party-mode";
import "./js/preview-bar";
import "./js/pwa";
import "./js/refresh";
import {getBlockContainer, initRainbowBlockReordering} from "./js/responsiveness";
import "./js/tracking/tracking";
import "./js/unregister-service-worker.js";
import * as widgets from "./js/widgets/all";
import "./scss/application-tailwind.scss";
import "./scss/application.scss";

$(function () {
    loadWidgets(widgets);

    const blockContainer = getBlockContainer();
    if (blockContainer) {
        initRainbowBlockReordering();
        minimizeBlocks();
    }

    checkConsentContent($("body"));
});

window.Alpine = Alpine;
Alpine.start();
